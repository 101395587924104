<template>
  <div>
    <!-- <div>
      <nav-gnb />
    </div> -->
    <div class="mt-1">
      <!-- 저장된 필터 목록-->
      <b-row>
        <b-col>
          <filter-list-new />
        </b-col>
      </b-row>

      <!-- 상단 버튼부 -->
      <b-row class="match-height">
        <b-col>
          <filter-check-list-table />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import NavGnb from '@/layouts/components/NavGnb.vue';
import ServiceButton from '../../layouts/button/ServiceButton.vue';
import FilterCheckListTable from './FilterCheckListTable.vue';
import FilterList from './FilterList.vue';
import FilterListNew from './FilterListNew.vue';

export default {
  components: {
    NavGnb,
    FilterCheckListTable,
    BRow,
    BCol,
    ServiceButton,
    FilterList,
    FilterListNew,
  },
  computed: {},
  mounted() {
    // gis delete..
  },
};
</script>

<style></style>
