<template>
  <div>
    <b-button
      v-b-toggle.searchForm
      size="sm"
      variant="warning"
      @click="openFilterSearchForm()"
    >
      <span class="align-middle">저장된 필터 목록</span>
    </b-button>

    <b-sidebar
      id="searchForm"
      ref="searchForm"
      backdrop
      bg-variant="white"
      shadow
      width="600px"
    >
      <div style="padding:10px 0px;">
        <h5 class="savedFilterTitle font-weight-bolder">
          저장된 필터 목록
        </h5>
        <div style="display:inline-block; width:25%;">
          <select style="width:93%;" @input="getFilterNewOrder($event)">
            <option value="DESC">
              최신순
            </option>
            <option value="ASC">
              오래된 순
            </option>
          </select>
        </div>
      </div>

      <div style="width:98%">
        <div v-for="val in filterInfo" :key="val.cdId" class="filterListWrap">
          <div class="filterListDiv" style="width:83%">
            <span>{{ val.filterNm }}</span>
            <b-overlay class="text-primary" rounded="xl" style="margin-top:6px">
              <div class="mt-2 filterIconArea">
                <span v-if="val.platOcpCd != null && val.platOcpCd !== ''">
                  <b-badge
                    v-for="platOcpCd in val.platOcpCd.split(',')"
                    class="filterBadge color1"
                    >{{ platOcpCd }}
                  </b-badge>
                </span>
                <span v-if="val.regstrGbCd != null && val.regstrGbCd !== ''">
                  <b-badge
                    v-for="regstrGbCd in val.regstrGbCd.split(',')"
                    class="filterBadge color2"
                    >{{ regstrGbCd }}
                  </b-badge>
                </span>
                <span v-if="val.ownerTypeCd != null && val.ownerTypeCd !== ''">
                  <b-badge
                    v-for="ownerTypeCd in val.ownerTypeCd.split(',')"
                    class="filterBadge color3"
                    >{{ ownerTypeCd }}
                  </b-badge>
                </span>
                <span v-if="val.kmaAreaCd != null && val.kmaAreaCd !== ''">
                  <b-badge
                    v-for="kmaAreaCd in val.kmaAreaCd.split(',')"
                    class="filterBadge color4"
                    >{{ kmaAreaCd }}
                  </b-badge>
                </span>
                <span v-if="val.sidoId != null && val.sidoId !== ''">
                  <b-badge
                    v-for="sidoId in val.sidoId.split(',')"
                    class="filterBadge color5"
                    >{{ sidoId }}
                  </b-badge>
                </span>
                <span v-if="val.sigunguId != null && val.sigunguId !== ''">
                  <b-badge
                    v-for="sigunguId in val.sigunguId.split(',')"
                    class="filterBadge color6"
                    >{{ sigunguId }}
                  </b-badge>
                </span>
                <span v-if="val.purpsGbCd != null && val.purpsGbCd !== ''">
                  <b-badge
                    v-for="purpsGbCd in val.purpsGbCd.split(',')"
                    class="filterBadge color7"
                    >{{ purpsGbCd }}
                  </b-badge>
                </span>
                <span v-if="val.mainPurpsCd != null && val.mainPurpsCd !== ''">
                  <b-badge
                    v-for="mainPurpsCd in val.mainPurpsCd.split(',')"
                    class="filterBadge color8"
                    >{{ mainPurpsCd }}
                  </b-badge>
                </span>
                <span v-if="val.typclUseCd != null && val.typclUseCd !== ''">
                  <b-badge
                    v-for="typclUseCd in val.typclUseCd.split(',')"
                    class="filterBadge color9"
                    >{{ typclUseCd }}
                  </b-badge>
                </span>
                <span v-if="val.dtldUseCd != null && val.dtldUseCd !== ''">
                  <b-badge
                    v-for="dtldUseCd in val.dtldUseCd.split(',')"
                    class="filterBadge color10"
                    >{{ dtldUseCd }}
                  </b-badge>
                </span>
                <span v-if="val.insltStCd != null && val.insltStCd != ''">
                  <b-badge
                    v-for="insltStCd in val.insltStCd.split(',')"
                    class="filterBadge color11"
                    >{{ insltStCd }}
                  </b-badge>
                </span>
                <span v-if="val.anualStCd != null && val.anualStCd !== ''">
                  <b-badge
                    v-for="anualStCd in val.anualStCd.split(',')"
                    class="filterBadge color12"
                    >{{ anualStCd }}
                  </b-badge>
                </span>
                <span v-if="val.totareaCd != null && val.totareaCd !== ''">
                  <b-badge
                    v-for="totareaCd in val.totareaCd.split(',')"
                    class="filterBadge color13"
                    >{{ totareaCd }}
                  </b-badge>
                </span>
                <span
                  v-if="val.anualOpdysCd != null && val.anualOpdysCd !== ''"
                >
                  <b-badge
                    v-for="anualOpdysCd in val.anualOpdysCd.split(',')"
                    class="filterBadge color14"
                    >{{ anualOpdysCd }}
                  </b-badge>
                </span>
                <span v-if="val.avrgOphrsCd != null && val.avrgOphrsCd !== ''">
                  <b-badge
                    v-for="avrgOphrsCd in val.avrgOphrsCd.split(',')"
                    class="filterBadge color15"
                    >{{ avrgOphrsCd }}
                  </b-badge>
                </span>
                <span v-if="val.useYyCd != null && val.useYyCd !== ''">
                  <b-badge
                    v-for="useYyCd in val.useYyCd.split(',')"
                    class="filterBadge color16"
                    >{{ useYyCd }}
                  </b-badge>
                </span>
                <span v-if="val.engyKindCd != null && val.engyKindCd !== ''">
                  <b-badge
                    v-for="engyKindCd in val.engyKindCd.split(',')"
                    class="filterBadge color17"
                    >{{ engyKindCd }}
                  </b-badge>
                </span>
                <span v-if="val.clsfKindCd != null && val.clsfKindCd !== ''">
                  <b-badge
                    v-for="clsfKindCd in val.clsfKindCd.split(',')"
                    class="filterBadge color18"
                    >{{ clsfKindCd }}
                  </b-badge>
                </span>
                <span
                  v-if="val.evlpAreaTimeCd != null && val.evlpAreaTimeCd !== ''"
                >
                  <b-badge
                    v-for="evlpAreaTimeCd in val.evlpAreaTimeCd.split(',')"
                    class="filterBadge color19"
                    >{{ evlpAreaTimeCd }}
                  </b-badge>
                </span>
                <span v-if="val.evlpInfoCd != null && val.evlpInfoCd !== ''">
                  <b-badge
                    v-for="evlpInfoCd in val.evlpInfoCd.split(',')"
                    class="filterBadge color20"
                    >{{ evlpInfoCd }}
                  </b-badge>
                </span>
                <span v-if="val.rgnGbCd != null && val.rgnGbCd !== ''">
                  <b-badge
                    v-for="rgnGbCd in val.rgnGbCd.split(',')"
                    class="filterBadge color21"
                    >{{ rgnGbCd }}
                  </b-badge>
                </span>
                <span v-if="val.repPurpsCd != null && val.repPurpsCd !== ''">
                  <b-badge
                    v-for="repPurpsCd in val.repPurpsCd.split(',')"
                    class="filterBadge color22"
                    >{{ repPurpsCd }}
                  </b-badge>
                </span>
                <span v-if="val.dtlPurpsCd != null && val.dtlPurpsCd !== ''">
                  <b-badge
                    v-for="dtlPurpsCd in val.dtlPurpsCd.split(',')"
                    class="filterBadge color23"
                    >{{ dtlPurpsCd }}
                  </b-badge>
                </span>
              </div>
            </b-overlay>
          </div>
          <div class="filterListDiv" style="width:17%; padding-left:5px;">
            <div>
              {{ val.rgtDt }}
            </div>
            <div style="text-align:center;">
              <span
                v-b-toggle.searchForm
                class="filterIcon"
                @click="goFilterModal(val.filterNm)"
              >
                <img
                  src="/gis/map/common/images/icon/icon-search.png"
                  style="padding-left:7px;"
                >
              </span>
              <span class="filterIcon" @click="deleteFilter(val.filterNm)">
                <img src="/gis/map/common/images/icon/icon-delete.png">
              </span>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>

    <b-modal
      v-model="modalType"
      centered
      ok-only
      ok-title="확인"
      size="xl"
      title="필터조회내역"
    >
      <b-overlay :show="show" class="text-primary" rounded="xl">
        <div>
          <filter-static-summary-table-renew />
        </div>
        <div>
          <filter-box-plot-chart-renew />
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BModal,
  BOverlay,
  BSidebar,
  VBModal,
  VBToggle,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import Ripple from 'vue-ripple-directive';
import { utils } from '@/utils/commUtils';
import FilterStaticSummaryTableRenew from './FilterStaticSummaryTableRenew.vue';
import FilterBoxPlotChartRenew from './FilterBoxPlotChartRenew.vue';

export default {
  components: {
    FilterStaticSummaryTableRenew,
    FilterBoxPlotChartRenew,
    BButton,
    BSidebar,
    BModal,
    BOverlay,
    BBadge,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  data() {
    return {
      modalType: false,
      orderOption: '1',
    };
  },
  computed: {
    ...mapGetters({
      filterInfo: 'filter/getFilterInfo', // 필터 정보
      filterFetchedInfo: 'filter/getFilterFetchedInfo',
      // 서머리 전용 신규생성
      filterSummaryInfo: 'filter/getFilterSummaryInfo',
      show: 'filter/getShowOverlay',
    }),
  },
  methods: {
    // 필터 정보 팝업을 열 때, 공통코드 데이터를 조회함
    openFilterSearchForm() {
      const userId = this.$store.state.auth.usrId;
      const { orderOption } = this;
      this.$store.dispatch('filter/FETCH_FILTER_INFO_NEW', { orderOption, userId });
      // this.$store.dispatch("filter/FETCH_FILTER_INFO");
    },
    async goFilterModal(filterNm) {
      this.modalType = !this.modalType;

      // 받는 부분이 json 타입이라 강제 설정
      this.filterFetchedInfo.filterNm = filterNm;

      // 저장된 필터명으로 저장된 데이터 로드
      await this.$store.dispatch('filter/FETCH_FILTER_LOAD_DATA', this.filterFetchedInfo);

      if (this.filterFetchedInfo.openSvcId.length > 0) {
        await this.$store.dispatch('filter/FETCH_OPNSVC_LOAD_LIST', this.filterFetchedInfo);
      }

      await this.$store.dispatch('filter/FETCH_COMM_CODE_DATA_SUMMARY');
    },
    deleteFilter(filterNm) {
      utils.fnConfirm(this, '삭제하시겠습니까?', `[${filterNm}] 필터 조건이 삭제됩니다.`)
        .then(async (res) => {
          if (res.value) {
            // 사용자가 확인 눌럿을 경우
            // 필수 조건 체크(저장 필터명에 값이 있어야 함)
            const { orderOption } = this;
            await this.$store.dispatch('filter/DELETE_FILTER_INFO', {
              filterNm,
              orderOption,
            });

            const resFlag = await this.$store.getters['filter/getResult'];
            utils.fnAlert(this, resFlag.resultMsg);
          }
        });
    },
    async getFilterNewOrder(event) {
      this.orderOption = event.target.value;
      const { orderOption } = this;
      const userId = this.$store.state.auth.usrId;
      await this.$store.dispatch('filter/FETCH_FILTER_INFO_NEW', {
        orderOption,
        userId,
      });
    },
  },
};
</script>

<style lang="scss">
.table-style {
  padding: 10px;
}

#searchForm {
  padding: 15px;
}

.savedFilterTitle {
  display: inline-block;
  width: 75%;
}

.filterListWrap {
  width: 100%;
  background: #f6f6f9;
  margin-bottom: 10px;
  padding: 20px 10px 20px 20px;
}

.filterListDiv {
  display: inline-block;
  vertical-align: bottom;
  height: 110px;
}

.filterIconArea {
  background: white;
  min-height: 80px;
  max-height: 80px;
  overflow-y: scroll;
  margin: 0 !important;
  padding: 8px;
}

.filterIcon {
  display: block;
  padding: 8px;
}

.filterIcon img {
  cursor: pointer;
}

.filterBadge {
  margin: 3px;
}

.color1 {
  background: #ea5455;
}

.color2 {
  background: #ff9f43;
}

.color3 {
  background: #ffc107;
}

.color4 {
  background: #28c76f;
}

.color5 {
  background: #00cfe8;
}

.color6 {
  background: #20c997;
}

.color7 {
  background: #17a2b8;
}

.color8 {
  background: #7367f0;
}

.color9 {
  background: #6610f2;
}

.color10 {
  background: #b8c2cc;
}

.color11 {
  background: #1e1e1e;
}

.color12 {
  background: #e83e8c;
}

.color13 {
  background: #956639;
}

.color14 {
  background: #10163a;
}

.color15 {
  background: #c4bbf7;
}

.color16 {
  background: #f1a899;
}

.color17 {
  background: #88a13c;
}

.color18 {
  background: #4279bd;
}

.color19 {
  background: #676d7d;
}

.color20 {
  background: #177240;
}

.color21 {
  background: #834900;
}

.color22 {
  background: #d61518;
}

.color23 {
  background: #2a3e44;
}
</style>
